import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="最高の叡智をあなたに。学者の名言！"
        pagepath="/feature/10/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/gakusha01.jpg"
							alt="最高の叡智をあなたに。学者の名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">最高の叡智をあなたに。学者の名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>世界の謎の探求に全身全霊を傾ける学者たち。</span>
                        <span>偉大な智慧を獲得した彼らの言葉には本質が含まれます。</span>
                        <span>
                          苦悩と努力の果てに知り得た彼らの叡智をご紹介します！<br />
                          きっと、あなたの人生のヒントになることでしょう！
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>歴史に残る名著、「夜と霧」の作者、ヴィクトール・フランクル。</p>
                        <p>ナチスドイツによるユダヤ人収容施設での壮絶な体験を元に、<span className="marker-b">r人間が如何にして生きるのか</span>を解き明かしました。</p>
                        <p>そんなフランクルの言葉は、限りない説得力を持って私たちに訴えかけてきます。</p>
                        <p>どのような絶望的な状況にあっても、私たちは常に<span className="f-l">「どう行動するか」</span>という意志があります。</p>
                        <p>状況は他人よって影響を受けます。しかし、<span className="marker-b">自分の意志は他人から影響されることのない聖域</span>です。</p>
                        <p>どうか、<span className="marker-b">正しく生きる強い意志</span>をもってください。</p>
                        <p>あなたがあなたたる理由は、<span className="f-l">どんな行動をしたか</span>、のみが規定します。</p>
                        <p>辛くなったらこの言葉に戻りましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>人生は苦に満ちています。</p>
                        <p>仏教の開祖、ゴータマ・シッダールタは、<span className="f-l">四聖諦</span>という教えでこれを説明しました。</p>
                        <p>私たちは、煩悩があります。エゴと言い換えても構いません。</p>
                        <p>煩悩、エゴとは、<span className="marker-b">自分だけが幸福になりたいという思い</span>です。</p>
                        <p>ゴータマ・シッダールタはこの煩悩が、<span className="f-l">苦悩の原因だと断言</span>します。</p>
                        <p>私たちが煩悩で生きる限り、苦悩はセットなのです。</p>
                        <p>だから、<span className="marker-y">人生に苦があることは当たりまえ</span>の話です。</p>
                        <p>賢者は、苦悩があることを嘆きません。<span className="marker-y">苦悩をどう乗り越えるかにフォーカス</span>します。</p>
                        <p>ニーチェは、そのことを知り、この言葉を残したのでしょう。</p>
                        <p>あなたにもニーチェの強さが宿りますように。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>忍耐を怠り、何かを手に入れた人はいません。</p>
                        <p>どんな天才であれ、<span className="marker-b">努力なしに営業を成し遂げることはできません。</span></p>
                        <p>そんな当たり前のことを、私たちは<span className="f-l">よく忘れてしまいます。</span></p>
                        <p>ノウハウや、近道を探してしまったり、情報商材等に騙されてしまうのはこのためです。</p>
                        <p>偉業には忍耐が必要だという、確固たる事実を認識する必要があります。</p>
                        <p>その覚悟があれば、安易な道に騙される事はありません。</p>
                        <p>辛くなったときには、このベンジャミンフランクリンの言葉を思い出しましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>人類史上最強のの天才、レオナルド・ダ・ヴィンチ</p>
                        <p>イタリア人芸術家である彼は、その卓越した芸術的センスもさることながら、<span className="f-l">ジャンル横断的な専門性を発揮</span>したことで知られています。</p>
                        <p>レオナルド・ダ・ヴィンチの特徴を一言で言うならば、その<span className="f-l">興味の対象の広さと集中力の強さ</span>でしょう。</p>
                        <p><span className="marker-y">あらゆることに興味を持ち、驚異的な集中力でそれを学習する。</span></p>
                        <p>人生と言う短い時間の中で、彼が残した業績を鑑みるとそれ以外に理由がつけられません。</p>
                        <p>公教育と言う狭い枠の中で<span className="marker-b">好奇心を限定してはいけません。</span></p>
                        <p>ダヴィンチのように、<span className="marker-b">やりたいと思うことに時間と労力を割きましょう。</span></p>
                        <p>興味のある対象にしか時間を使ってはいけません。</p>
                        <p>それを繰り返していれば、あなたは第二のレオナルド・ダ・ヴィンチと呼ばれる日が来ます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>アリストテレスの皮肉の利いた言葉です。</p>
                        <p>確かに振り返ってみると、<span className="f-l">本当に価値ある友人、親友と呼べる友達は数えるほど</span>しかいません。</p>
                        <p>本来であれば、そのような大切な友人との時間を長く持つべきです。</p>
                        <p>しかし私たちは、多くの友人をとっかえひっかえ囲っています。</p>
                        <p>それでは本当に大切な友との友情を育むことができません。</p>
                        <p>アリストテレスの神の言葉を忠実に実行するならば、本当に価値ある友人にこそ、時間を使うべきだということです。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>私たちは様々な<span className="marker-y">道徳規範</span>によって生きています。</p>
                        <p>さらに現代においては、様々な価値基準がその上に乗っかっています。</p>
                        <p>例えば、<span className="marker-y">資本主義</span>。お金を持つことが幸せにつながる。お金を持っている人が偉い。そんな価値観を無意識に抱いていないでしょうか？</p>
                        <p>価値観が氾濫すると、<span className="f-l">私たちはとるべき行動を見失ってしまいます。</span></p>
                        <p>まずは、<span className="f-l">自分が本当に価値のあると思う価値観を定めること</span>が必要です。</p>
                        <p>それは仏教的価値観でもいいし、もちろんキリスト教的価値観でも良い、もしくは学校教育で習った道徳的観念でも構いません。</p>
                        <p>あなたが信じる価値観を確固たるものにしましょう。</p>
                        <p>あなたが信じる価値観が決まれば、<span className="f-l">とるべき正しい行動と言うのは自然と見えてきます。</span></p>
                        <p>あとは実行する勇気を持つだけです。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>現代に、<span className="marker-b">切符を切る駅員さんはいません。</span><span className="marker-y">家に氷を運ぶ氷屋さん</span>もいません。</p>
                        <p>テクノロジーの進化により、なくなった仕事があります。</p>
                        <p>同時に<span className="f-l">テクノロジーの進化により、新しく生まれた仕事があります。</span></p>
                        <p>新しく生まれた仕事は、その誕生を華々しく迎え入れられたわけではありません。</p>
                        <p>ジョンロックかっぱするように、<span className="f-l">まだ一般的ではない</span>という理由で、新しいアイディアは否定され続けてきました。</p>
                        <p>それでもなお、その有用性と価値を正しく認識している人が、新しいアイデアを実現してきました。</p>
                        <p>固定観念に囚われることなく、新しいアイディアを生み出し続けたいですね。</p>
                        <p>その際に、<span className="marker-b">反対意見に耳を貸すのはあまり賢いことではありません。</span></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>人間は、<span className="f-l">死刑囚のようなもの</span>です。</p>
                        <p>なぜなら<span className="f-l">死ぬことが確定している</span>からです。</p>
                        <p>確定していない事は、<span className="f-l">いつ死ぬか</span>ということ。</p>
                        <p>牢屋に入れられ、死刑執行の日を待つ囚人と何ら変わることはありません。</p>
                        <p>この自明の真実を、<span className="marker-y">どれほどの人が自分事として捉えている</span>でしょうか。</p>
                        <p>おそらくほとんどの人が、<span className="marker-y">自分の死に関しては忘れている、もしくは見ないようにしている</span>のです。</p>
                        <p>ただしそれは建設的な思考とは言えません。</p>
                        <p>私たちが永遠の命を持っているのであれば、私を考える事は無意味ですが、<span className="marker-b">私たちは必ず死にます。死を考えないことは怠慢</span>ともいえます。</p>
                        <p>私を考えることによって、<span className="f-l">今、この瞬間の活力</span>がわきます。</p>
                        <p>今を大切に、有意義に生きたいなら死を考えることはとてもメリットがあります。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ウィリアム・ジェームズは、アメリカの哲学者、心理学者。</p>
                        <p>他にもたくさんの名言を残したことで、世界的に有名です。</p>
                        <p><span className="f-l">笑うから楽しくなると言うこと</span>は、現代の科学的にも正しいことが証明されつつあります。</p>
                        <p><span className="marker-b">笑顔に似た表情をつくると、ドーパミンが活性化します。</span></p>
                        <p>ドーパミンは、脳の｢快楽｣に関係した神経伝達物質なので、楽しくなくても笑顔をつくれば｢幸福物質｣が脳に出てくるのです。</p>
                        <p>ジェームスがどうやってこの事実に気づいたかどうかはわかりませんが、<span className="marker-y">素晴らしい叡智が短い言葉に詰められた名言ですね。</span></p>
                        <p><span className="marker-b">気分が落ち込んでいる、不安で押しつぶされそうだ、人間関係がうまくいかない</span>、そんなこともありますよね。そんな人こそ笑顔作ってみてはいかがですか</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p><span className="f-l">馬を水飲み場に連れて行くことはできても、馬に水を飲ませることはできない。</span></p>
                        <p>ということわざがあります。</p>
                        <p>人から強制されてやらされた事は、<span className="marker-y">興味が生まれません。</span></p>
                        <p>そこに教育のジレンマがありますね。</p>
                        <p>教育者たる人は、<span className="marker-b">人々にこれは面白いことなんだ</span>と紹介することぐらいしかできないのかもしれませんね。</p>
                        <p>トーマスエジソンを始め、<span className="f-l">独学で自分の境地を切り開いていった天才たち</span>がいます。</p>
                        <p>彼らは、<span className="f-l">自分で興味を開拓し、追求していた人</span>でした。</p>
                        <p>そうなれば彼らの一人旅。<span className="marker-b">教育者はその旅の準備を手伝ってあげる</span>ことしかできません。</p>
                        <p>親、教師、友人、兄弟。様々な立場から人を指導することが生まれると思いますが、この原則を覚えておきたいですね。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "19afaced-8b58-5731-9616-ea16a3b7341d"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "51f7755b-8499-598c-8861-90ad032d1fd0"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "f6926749-afff-569b-ba73-4bb73aa74401"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "12c32af0-5650-502b-b845-17d4cb254f6b"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "5727636f-b1aa-5861-af0f-a71b6a9d1198"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "74732112-0f69-5ba4-ade5-3f850868c12d"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "7f9f495c-e59d-5383-b627-6dcf5fd0d308"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "cce95899-61fd-5741-bcaa-bd2cbd7ad60e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "df357314-e67a-5820-a5db-e08325f31589"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "b3b78ae6-e7de-50d8-8651-84a7c7f569aa"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home